<template>
  <div style="text-align: left !important">
    <div class="home">
      <div class="d-block d-md-block d-lg-none d-xl-none">
        <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-mobnav">
          <a class="navbar-brand mr-auto mr-lg-0" href="#">
            <img
              src="https://sotreq.com.br/img/logosotreq.2d97c53d.svg"
              width="120"
              height="30"
            />
          </a>
          <a data-toggle="modal" data-target="#exampleModal">
            <i class="fa fa-search fa-2x mob-search"></i>
          </a>
          <button
            class="navbar-toggler p-0 border-0"
            type="button"
            data-toggle="offcanvas"
          >
            <i class="fa fa-bars fa-lg text-black"></i>
          </button>
          <div
            class="navbar-collapse offcanvas-collapse"
            id="navbarsExampleDefault"
          >
            <div id="collapsemOb" class="accordion">
              <div class="mob-card">
                <div id="headingOne" class="mob-card-header">
                  <h2 class="mb-0">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      class="btn btn-link btn-block text-left collapsed cool-link"
                    >
                      <span class="colFirstLevel">Soluções para você</span>
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne"
                  aria-labelledby="headingOne"
                  data-parent="#collapsemOb"
                  class="collapse show"
                >
                  <div class="mob-card-body mob-menu-card-body">
                    <div class="mob-card rounded-0 border-0 card-menu-servicos">
                      <div id="collapsemObs1" class="accordion">
                        <div class="mob-card">
                          <div id="headingOnes1" class="mob-card-header">
                            <h2 class="mb-0">
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes1"
                                aria-expanded="true"
                                aria-controls="collapseOnes1"
                                class="btn btn-link btn-block text-left collapsed cool-link"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Mercados</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOnes1"
                            aria-labelledby="headingOnes1"
                            data-parent="#collapsemObs1"
                            class="collapse show"
                          >
                            <div class="mob-card-body mob-menu-card-body">
                              <div
                                class="mob-card rounded-0 border-0 card-menu-servicos"
                              >
                                <ul class="nav flex-column">
                                  <ul class="mob-menu-list">
                                    <li class="nav-item">
                                      <a
                                        href="/#/areiaebrita"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Areia e brita</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/agricola"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Agrícola</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/construcao"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Construção</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/energia"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Energia</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/florestal"
                                        class="mob-internal-link"
                                      >
                                        <h6>Florestal</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/industrial"
                                        class="mob-internal-link"
                                      >
                                        <h6>Industrial</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/maritimo"
                                        class="mob-internal-link"
                                      >
                                        <h6>Marítimo</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/mineracao"
                                        class="mob-internal-link"
                                      >
                                        <h6 class="mob-internal-link">
                                          Mineração
                                        </h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/oleoegas"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Óleo e gás</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pavimentacao"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Pavimentação</h6>
                                      </a>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mob-card">
                          <div id="headingOnes2" class="mob-card-header">
                            <h2 class="mb-0">
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes2"
                                aria-expanded="true"
                                aria-controls="collapseOnes2"
                                class="btn btn-link btn-block text-left collapsed cool-link"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Produtos</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOnes2"
                            aria-labelledby="headingOnes2"
                            data-parent="#collapsemObs1"
                            class="collapse show"
                          >
                            <div class="mob-card-body mob-menu-card-body">
                              <div
                                class="mob-card rounded-0 border-0 card-menu-servicos"
                              >
                                <ul class="nav flex-column">
                                  <ul class="mob-menu-list">
                                    <li class="nav-item">
                                      <a
                                        href="/#/nossasmaquinas"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Maquinas</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="https://sotreqseminovos.com.br"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Seminovos</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/acessorios"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Acessórios</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/aluguel"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Aluguel</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item mb-4">
                                      <a
                                        href="/#/grupo-geradores-e-motores"
                                        class="mob-internal-link"
                                      >
                                        <h6 class="mob-internal-link">
                                          Grupo geradores e motores
                                        </h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="http://sotreqenergia.com.br"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Geração de energia</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/motores"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Óleo e gás</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/motores/maritimo"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Marítimo</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item mb-4">
                                      <a
                                        href="/#/motores/industrial"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Industrial</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/sistema-propulsao"
                                        class="mob-internal-link"
                                      >
                                        <h6>Sistemas de propulsão</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/filtragem"
                                        class="mob-internal-link"
                                      >
                                        <h6>Sistema de filtragem</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/transmissoes"
                                        class="mob-internal-link"
                                      >
                                        <h6>Transmissões</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="https://shopcat.brprop.com.br"
                                        class="mob-internal-link"
                                      >
                                        <h6>ShopCat</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/produtos/mangueiras"
                                        class="mob-internal-link"
                                      >
                                        <h6>Mangueiras</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/produtos/bombas-de-servico-de-poco"
                                        class="mob-internal-link"
                                      >
                                        <h6>Bombas de serviço de poço</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="internal-link" href="/#/fps">
                                        <h6>
                                          Ferramenta de Penetração de Solo
                                        </h6>
                                      </a>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mob-card">
                          <div id="headingOnes3" class="mob-card-header">
                            <h2 class="mb-0">
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes3"
                                aria-expanded="true"
                                aria-controls="collapseOnes3"
                                class="btn btn-link btn-block text-left collapsed cool-link"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Peças</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOnes3"
                            aria-labelledby="headingOnes3"
                            data-parent="#collapsemObs1"
                            class="collapse show"
                          >
                            <div class="mob-card-body mob-menu-card-body">
                              <div
                                class="mob-card rounded-0 border-0 card-menu-servicos"
                              >
                                <ul class="nav flex-column">
                                  <ul class="mob-menu-list">
                                    <li class="nav-item">
                                      <a
                                        href="https://parts.cat.com/pt/sotreq"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Compre peças online</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pecas-genuinas-cat-r"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Peças Genuínas Cat ®</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pecas/cjc"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Peças CJC</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pecas/emd"
                                        class="mob-internal-link"
                                      >
                                        <h6>Peças EMD</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pecas/mak"
                                        class="mob-internal-link"
                                      >
                                        <h6>Peças MAK</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pecas-usadas"
                                        class="mob-internal-link"
                                      >
                                        <h6>Peças usadas</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="#/pecas/pneus"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Pneus</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/pecas/spbt"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>
                                          SPBT - Serviço de peças a base de
                                          troca
                                        </h6>
                                      </a>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mob-card">
                          <div id="headingOnes4" class="mob-card-header">
                            <h2 class="mb-0">
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes4"
                                aria-expanded="true"
                                aria-controls="collapseOnes4"
                                class="btn btn-link btn-block text-left collapsed cool-link"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Serviços</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOnes4"
                            aria-labelledby="headingOnes4"
                            data-parent="#collapsemObs1"
                            class="collapse show"
                          >
                            <div class="mob-card-body mob-menu-card-body">
                              <div
                                class="mob-card rounded-0 border-0 card-menu-servicos"
                              >
                                <ul class="nav flex-column">
                                  <ul class="mob-menu-list">
                                    <li class="nav-item">
                                      <a
                                        href="/#/lab-sos"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Laboratório SOS</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/contratos"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Contratos</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/opcoes-de-reparo"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Opções de reparo</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/crc"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>
                                          CRC - Remanufatura de componentes
                                        </h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="http://www.sotreqenergia.com.br/"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Serviço Energia</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos-manutencao"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Serviços de Manutenção</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/maritimo-comercial"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6 class="mob-internal-link">
                                          Serviço Marítimo Comercial
                                        </h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/campo"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Campo</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item mb-4">
                                      <a
                                        href="/#/servicos/oficina"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Oficina</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/oleo-e-gas"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6 class="mob-internal-link">
                                          Serviço Óleo e Gás
                                        </h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/campo"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Campo</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item mb-4">
                                      <a
                                        href="/#/servicos/oficina"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Oficina</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/maritimo-lazer"
                                        class="mob-internal-link"
                                      >
                                        <h6>Serviço Marítimo Lazer</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/servicos/treinamentos-tecnicos"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Treinamentos Técnicos</h6>
                                      </a>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mob-card">
                          <div id="headingOnes5" class="mob-card-header">
                            <h2 class="mb-0">
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes5"
                                aria-expanded="true"
                                aria-controls="collapseOnes5"
                                class="btn btn-link btn-block text-left collapsed cool-link"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Tecnologia</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOnes5"
                            aria-labelledby="headingOnes5"
                            data-parent="#collapsemObs1"
                            class="collapse show"
                          >
                            <div class="mob-card-body mob-menu-card-body">
                              <div
                                class="mob-card rounded-0 border-0 card-menu-servicos"
                              >
                                <ul class="nav flex-column">
                                  <ul class="mob-menu-list">
                                    <li class="nav-item animate__fadeInLeftBig">
                                      <a
                                        href="/#/aplicacoes"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Aplicações</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item animate__fadeInLeftBig">
                                      <a
                                        href="/#/aplicacoes/cat-connect"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Cat® Connect</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item animate__fadeInLeftBig">
                                      <a
                                        href="/#/aplicacoes/rajant"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Rajant</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item animate__fadeInLeftBig">
                                      <a
                                        href="/#/aplicacoes/drenagem-eficiente"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Drenagem eficiente</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <h6 class="mob-internal-link">
                                        Soluções Mineração
                                      </h6>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/mineracao/solucoes"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Soluções</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/mineracao/aplicacoes"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>Aplicações</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item animate__fadeInLeftBig">
                                      <a
                                        href="/#/aplicacoes/solucoes-maritimo"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Soluções Marítimo</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item animate__fadeInLeftBig">
                                      <a
                                        href="http://sotreqenergia.com.br/"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Soluções Energia</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <h6 class="mob-internal-link">
                                        Sotreq Link
                                      </h6>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/contratos/sotreq-link/lite"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>SotreqLink Lite</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/contratos/sotreq-link/classic"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>SotreqLink Classic</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/contratos/sotreq-link/plus"
                                        class="mob-internal-link text-muted"
                                      >
                                        <h6>SotreqLink Plus</h6>
                                      </a>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mob-card">
                          <div id="headingOnes6" class="mob-card-header">
                            <h2 class="mb-0">
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes6"
                                aria-expanded="true"
                                aria-controls="collapseOnes6"
                                class="btn btn-link btn-block text-left collapsed cool-link"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Financiamento</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOnes6"
                            aria-labelledby="headingOnes6"
                            data-parent="#collapsemObs1"
                            class="collapse show"
                          >
                            <div class="mob-card-body mob-menu-card-body">
                              <div
                                class="mob-card rounded-0 border-0 card-menu-servicos"
                              >
                                <ul class="nav flex-column">
                                  <ul class="mob-menu-list">
                                    <li class="nav-item">
                                      <a
                                        href="/#/financiamento"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Financiamentos</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/financiamento/finame"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link text-muted mb-4"
                                      >
                                        <h6>FINAME</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/financiamento/cat-financial"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Cat Financial</h6>
                                      </a>
                                    </li>
                                    <li class="nav-item">
                                      <a
                                        href="/#/financiamento/consorcios"
                                        tabindex="-1"
                                        aria-disabled="true"
                                        class="mob-internal-link"
                                      >
                                        <h6>Consórcios</h6>
                                      </a>
                                    </li>
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mob-card">
              <div
                id="headingTwo"
                class="mob-card-header animate__fadeInLeftBig animate__bounce animate__delay-2s"
              >
                <h2 class="mb-0">
                  <a href="/#/promocoes">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      class="btn btn-link btn-block text-left collapsed cool-link"
                    >
                      <span class="colFirstLevel"> Promoções </span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
            <div class="mob-card">
              <div id="headingThree" class="mob-card-header">
                <h2 class="mb-0">
                  <a href="/#/sobre-nos">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      class="btn btn-link btn-block text-left collapsed cool-link"
                    >
                      <span class="colFirstLevel"> Sobre a Sotreq </span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
            <div class="mob-card">
              <div
                id="headingTwo"
                class="mob-card-header animate__fadeInLeftBig animate__bounce animate__delay-2s"
              >
                <h2 class="mb-0">
                  <a href="/#/contato">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      class="btn btn-link btn-block text-left collapsed cool-link"
                    >
                      <span class="colFirstLevel"> Fale Conosco </span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div class="d-none d-md-none d-lg-block d-xl-block">
        <nav
          id="desknav"
          class="navbar navbar-expand-md navbar-dark navbar-sotreq"
        >
          <div class="container">
            <a href="/" class="navbar-brand">
              <img
                src="https://sotreq.com.br/img/logosotreq.2d97c53d.svg"
                alt=""
                class="header-logo"
              />
            </a>
            <button
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              class="navbar-toggler"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div id="navbarNavDropdown" class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <h1 id="output"></h1>
                <li class="nav-item dropdown">
                  <a
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="dropdown-item dropdown-toggle cool-link"
                    >Soluções para você</a
                  >
                  <ul
                    aria-labelledby="navbarDropdownMenuLink"
                    class="dropdown-menu"
                  >
                    <div class="container">
                      <li>
                        <a
                          href="#"
                          class="dropdown-item-in dropdown-toggle cool-link"
                          >Mercados</a
                        >
                        <ul class="dropdown-menu">
                          <div class="container">
                            <div class="d-flex py-4 w-100">
                              <div class="d-flex img-menu-box">
                                <div
                                  class="card rounded-0 border-0 card-menu-servicos"
                                  style="width: 16rem"
                                >
                                  <img
                                    src="/img/thumb_servicos.b53eb21e.jpg"
                                    alt="..."
                                    class="card-img-top rounded-0 border-0"
                                  />
                                  <div
                                    class="card-body rounded-0 border-0 px-0"
                                  >
                                    <p class="card-text">
                                      A Sotreq está presente nos principais
                                      segmentos da economia. Não importa o local
                                      de trabalho, nossas máquinas oferecem o
                                      melhor desempenho para o seu negócio.
                                      Confira nosso portfólio!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-grow-1">
                                <div
                                  class="w-100 d-flex justify-content-between"
                                >
                                  <div class="pl-4 mx-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/areiaebrita"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Areia e brita</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/agricola"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Agrícola</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/construcao"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Construção</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/energia"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Energia</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/florestal"
                                          class="internal-link"
                                        >
                                          <h6>Florestal</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/industrial"
                                          class="internal-link"
                                        >
                                          <h6>Industrial</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/maritimo"
                                          class="internal-link"
                                        >
                                          <h6>Marítimo</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/mineracao"
                                          class="internal-link"
                                        >
                                          <h6 class="internal-link">
                                            Mineração
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/oleoegas"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Óleo e gás</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/pavimentacao"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Pavimentação</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 d-flex justify-content-center">
                              <img
                                src="/img/up-arrow.790a865a.svg"
                                class="upcaret"
                              />
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="dropdown-item-in dropdown-toggle cool-link"
                          >Produtos</a
                        >
                        <ul class="dropdown-menu">
                          <div class="container">
                            <div class="d-flex py-4 w-100">
                              <div class="d-flex img-menu-box">
                                <div
                                  class="card rounded-0 border-0 card-menu-servicos"
                                  style="width: 16rem"
                                >
                                  <img
                                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0VEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--668e58ae5621811589edca81d4ea90fd6ebd0463/maquinas.jpg"
                                    alt="..."
                                    class="card-img-top rounded-0 border-0"
                                  />
                                  <div
                                    class="card-body rounded-0 border-0 px-0"
                                  >
                                    <p class="card-text">
                                      A Sotreq oferece um portfólio completo de
                                      produtos Cat
                                      <sup>®️</sup>
                                      para venda e aluguel, incluindo máquinas,
                                      acessórios e seminovos. Encontre a melhor
                                      solução!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-grow-1">
                                <div
                                  class="w-100 d-flex justify-content-between"
                                >
                                  <div class="pl-4 mx-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/nossasmaquinas"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Máquinas</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="https://sotreqseminovos.com.br"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Seminovos</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/acessorios"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Acessórios</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/aluguel"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Aluguel</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/grupo-geradores-e-motores"
                                          class="internal-link text-muted"
                                        >
                                          <h6 class="internal-link">
                                            Grupo geradores e motores
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="http://sotreqenergia.com.br"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Geração de energia</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/motores"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Óleo e gás</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/motores/maritimo"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Marítimo</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item mb-4">
                                        <a
                                          href="/#/motores/industrial"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Industrial</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/sistema-propulsao"
                                          class="internal-link"
                                        >
                                          <h6>Sistemas de propulsão</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/filtragem"
                                          class="internal-link"
                                        >
                                          <h6>Sistema de filtragem</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/transmissoes"
                                          class="internal-link"
                                        >
                                          <h6>Transmissões</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="https://shopcat.brprop.com.br"
                                          class="internal-link"
                                        >
                                          <h6>ShopCat</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/produtos/mangueiras"
                                          class="internal-link"
                                        >
                                          <h6>Mangueiras</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/produtos/bombas-de-servico-de-poco"
                                          class="internal-link"
                                        >
                                          <h6>Bombas de serviço de poço</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="mob-internal-link"
                                          href="/#/fps"
                                        >
                                          <h6>
                                            Ferramenta de Penetração de Solo
                                          </h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 d-flex justify-content-center">
                              <img
                                src="/img/up-arrow.790a865a.svg"
                                class="upcaret"
                              />
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="dropdown-item-in dropdown-toggle cool-link"
                          >Peças</a
                        >
                        <ul class="dropdown-menu">
                          <div class="container">
                            <div class="d-flex py-4 w-100">
                              <div class="d-flex img-menu-box">
                                <div
                                  class="card rounded-0 border-0 card-menu-servicos"
                                  style="width: 16rem"
                                >
                                  <img
                                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a13d3932d07e83863745f31f1d36b89a95e3eeee/pe%C3%A7as.jpg"
                                    alt="..."
                                    class="card-img-top rounded-0 border-0"
                                  />
                                  <div
                                    class="card-body rounded-0 border-0 px-0"
                                  >
                                    <p class="card-text">
                                      Nosso almoxarifado de peças genuínas Cat
                                      <sup>®️</sup>
                                      tem mais de 25 mil itens diferentes,
                                      incluindo seminovas. Todas dentro dos
                                      padrões de utilização recomendados.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-grow-1">
                                <div
                                  class="w-100 d-flex justify-content-between"
                                >
                                  <div class="pl-4 mx-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="https://parts.cat.com/pt/sotreq"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Compre peças online</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/pecas-genuinas-cat-r"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Peças Genuínas Cat ®</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/pecas/cjc"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Peças CJC</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/pecas/emd"
                                          class="internal-link"
                                        >
                                          <h6>Peças EMD</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/pecas/mak"
                                          class="internal-link"
                                        >
                                          <h6>Peças MAK</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/pecas-usadas"
                                          class="internal-link"
                                        >
                                          <h6>Peças usadas</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="#/pecas/pneus"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Pneus</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/pecas/spbt"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>
                                            SPBT - Serviço de peças a base de
                                            troca
                                          </h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 d-flex justify-content-center">
                              <img
                                src="/img/up-arrow.790a865a.svg"
                                class="upcaret"
                              />
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="dropdown-item-in dropdown-toggle cool-link"
                          >Serviços</a
                        >
                        <ul class="dropdown-menu">
                          <div class="container">
                            <div class="d-flex py-4 w-100">
                              <div class="d-flex img-menu-box">
                                <div
                                  class="card rounded-0 border-0 card-menu-servicos"
                                  style="width: 16rem"
                                >
                                  <img
                                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjhDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9728988a02ab67e5effc5dd2348d00e795e4186e/servicos.jpg"
                                    alt="..."
                                    class="card-img-top rounded-0 border-0"
                                  />
                                  <div
                                    class="card-body rounded-0 border-0 px-0"
                                  >
                                    <p class="card-text">
                                      A Sotreq oferece soluções completas e
                                      inovadoras para seus clientes. Com
                                      know-how técnico, garantimos os mais
                                      completos serviços de oficina.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-grow-1">
                                <div
                                  class="w-100 d-flex justify-content-between"
                                >
                                  <div class="pl-4 mx-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/lab-sos"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Laboratório SOS</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/contratos"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Contratos</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/opcoes-de-reparo"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Opções de reparo</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/crc"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>
                                            CRC - Remanufatura de componentes
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="http://www.sotreqenergia.com.br/"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Serviço Energia</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos-manutencao"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Serviços de Manutenção</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/maritimo-comercial"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link text-muted"
                                        >
                                          <h6 class="internal-link">
                                            Serviço Marítimo Comercial
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/campo"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Campo</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item mb-4">
                                        <a
                                          href="/#/servicos/oficina"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Oficina</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/oleo-e-gas"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6 class="internal-link">
                                            Serviço Óleo e Gás
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/campo"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Campo</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item mb-4">
                                        <a
                                          href="/#/servicos/oficina"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Oficina</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/maritimo-lazer"
                                          class="internal-link"
                                        >
                                          <h6>Serviço Marítimo Lazer</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/servicos/treinamentos-tecnicos"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Treinamentos Técnicos</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 d-flex justify-content-center">
                              <img
                                src="/img/up-arrow.790a865a.svg"
                                class="upcaret"
                              />
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="dropdown-item-in dropdown-toggle cool-link"
                          >Tecnologia</a
                        >
                        <ul class="dropdown-menu">
                          <div class="container">
                            <div class="d-flex py-4 w-100">
                              <div class="d-flex img-menu-box">
                                <div
                                  class="card rounded-0 border-0 card-menu-servicos"
                                  style="width: 16rem"
                                >
                                  <img
                                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--42a76a48897cb153c4fb14445f8b92080053d31d/tecnologia.jpg"
                                    alt="..."
                                    class="card-img-top rounded-0 border-0"
                                  />
                                  <div
                                    class="card-body rounded-0 border-0 px-0"
                                  >
                                    <p class="card-text">
                                      As tecnologias não param de evoluir.
                                      Focada na produtividade, a Sotreq trabalha
                                      com diversas soluções para garantir o
                                      melhor desempenho e maior economia nas
                                      operações da sua empresa.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-grow-1">
                                <div
                                  class="w-100 d-flex justify-content-between"
                                >
                                  <div class="pl-4 mx-0">
                                    <ul class="nav flex-column">
                                      <li
                                        class="nav-item animate__fadeInLeftBig"
                                      >
                                        <a
                                          href="/#/aplicacoes"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Aplicações</h6>
                                        </a>
                                      </li>
                                      <li
                                        class="nav-item animate__fadeInLeftBig"
                                      >
                                        <a
                                          href="/#/aplicacoes/cat-connect"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Cat® Connect</h6>
                                        </a>
                                      </li>
                                      <li
                                        class="nav-item animate__fadeInLeftBig"
                                      >
                                        <a
                                          href="/#/aplicacoes/rajant"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Rajant</h6>
                                        </a>
                                      </li>
                                      <li
                                        class="nav-item animate__fadeInLeftBig"
                                      >
                                        <a
                                          href="/#/aplicacoes/drenagem-eficiente"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Drenagem eficiente</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="#"
                                          class="internal-link text-muted notalink"
                                        >
                                          <h6 class="internal-link">
                                            Soluções Mineração
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/mineracao/solucoes"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Soluções</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item mb-4">
                                        <a
                                          href="/#/mineracao/aplicacoes"
                                          class="internal-link text-muted"
                                        >
                                          <h6>Aplicações</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/aplicacoes/solucoes-maritimo"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Soluções Marítimo</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="http://sotreqenergia.com.br/"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Soluções Energia</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="#"
                                          class="internal-link text-muted notalink"
                                        >
                                          <h6 class="internal-link">
                                            Sotreq Link
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/contratos/sotreq-link/lite"
                                          class="internal-link text-muted"
                                        >
                                          <h6>SotreqLink Lite</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/contratos/sotreq-link/classic"
                                          class="internal-link text-muted"
                                        >
                                          <h6>SotreqLink Classic</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/contratos/sotreq-link/plus"
                                          class="internal-link text-muted"
                                        >
                                          <h6>SotreqLink Plus</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 d-flex justify-content-center">
                              <img
                                src="/img/up-arrow.790a865a.svg"
                                class="upcaret"
                              />
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="dropdown-item-in dropdown-toggle cool-link"
                          >Financiamento</a
                        >
                        <ul class="dropdown-menu">
                          <div class="container">
                            <div class="d-flex py-4 w-100">
                              <div class="d-flex img-menu-box">
                                <div
                                  class="card rounded-0 border-0 card-menu-servicos"
                                  style="width: 16rem"
                                >
                                  <img
                                    src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb01EIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9430e64b2084532f84908b4f177dc1ef9d1a1574/menu_financiamento.jpg"
                                    alt="..."
                                    class="card-img-top rounded-0 border-0"
                                  />
                                  <div
                                    class="card-body rounded-0 border-0 px-0"
                                  >
                                    <p class="card-text">
                                      Conte com a Cat
                                      <sup>®️</sup>
                                      para financiar linhas completas de
                                      máquinas e equipamentos. A Cat Financial
                                      ajuda clientes da Sotreq com as melhores
                                      soluções de empréstimo.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex flex-grow-1">
                                <div
                                  class="w-100 d-flex justify-content-between"
                                >
                                  <div class="pl-4 mx-0">
                                    <ul class="nav flex-column">
                                      <li class="nav-item">
                                        <a
                                          href="/#/financiamento"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link text-muted"
                                        >
                                          <h6 class="internal-link">
                                            Financiamentos
                                          </h6>
                                        </a>
                                      </li>
                                      <li class="nav-item mb-4">
                                        <a
                                          href="/#/financiamento/finame"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link text-muted"
                                        >
                                          <h6>FINAME</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/financiamento/cat-financial"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Cat Financial</h6>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          href="/#/financiamento/consorcios"
                                          tabindex="-1"
                                          aria-disabled="true"
                                          class="internal-link"
                                        >
                                          <h6>Consórcios</h6>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column"></ul>
                                  </div>
                                  <div class="pr-0">
                                    <ul class="nav flex-column"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="w-100 d-flex justify-content-center">
                              <img
                                src="/img/up-arrow.790a865a.svg"
                                class="upcaret"
                              />
                            </div>
                          </div>
                        </ul>
                      </li>
                    </div>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a href="/#/promocoes" class="nav-link cool-link"
                    >Promoções</a
                  >
                </li>
                <li class="nav-item">
                  <a href="/#/sobre-nos" class="nav-link cool-link"
                    >Sobre a Sotreq</a
                  >
                </li>
                <li class="nav-item">
                  <a href="/#/contato" class="nav-link cool-link"
                    >Fale conosco</a
                  >
                </li>
                <li class="nav-item dropdown">
                  <a
                    id="btnbusca"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    class="dropdown-item dropdown-toggle cool-link"
                  >
                    <i class="fa fa-search"></i>
                  </a>
                </li>
                <a href="https://sotreqlink.sotreq.com.br/Login">
                  <button
                    type="button"
                    id="activator"
                    class="btn btn-outline-warning sotreqlink-btn"
                  >
                    SotreqLink
                  </button>
                </a>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="herohome">
      <div
        class="d-block d-sm-block d-md-block d-lg-none"
        style="margin-top: 80px"
      >
        <img
          src="https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcEVIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1dced29642baf779c8a3bfd2fe705694b163f757/hero.jpg"
          alt="HeroMercados"
          class="img-fluid"
        />
        <div
          class="container my-5 my-md-0 d-flex align-items-center text-left h-100"
        >
          <div class="text-black text-left">
            <h1 class="font-weight-bold display-4">
              Seletor de Ferramenta de Penetração de Solo Cat
            </h1>
            <div class="bar"></div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-none d-lg-block">
        <div class="hero-overlay"></div>
        <div
          class="hero-img active"
          style="
            background-image: url(https://api-site-mig.sotreq.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcEVIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1dced29642baf779c8a3bfd2fe705694b163f757/hero.jpg);
          "
        >
          <div class="container d-flex align-items-center text-left h-100 p-0">
            <div class="col-8 text-white text-left">
              <h1 class="display-4 font-weight-bold lh-sm">
                Seletor de Ferramenta de <br />
                Penetração de Solo Cat
                <!---->
              </h1>
              <div class="bar mt-1"></div>
              <div>
                <h5 class="font-weight-light">
                  Encontrar a ferramenta de penetração de solo perfeita para sua
                  operação <br />
                  ficou mais fácil. Utilize nosso seletor e descubra a solução
                  ideal para<br />
                  maximizar seu desempenho!
                </h5>
                <!---->
                <!---->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div id="searchbox" class="container mb-0 hidden"></div>
    <div class="container"  style="margin-top: 19px">
      <p class="title-bold">Seletor de FPS para equipamentos Cat.</p>
      <p>
        Precisando escolher o FPS certo para sua máquina Cat? Nossa ferramenta
        completa te guia passo a passo para encontrar a solução ideal,
        considerando as características do seu equipamento e as exigências do
        seu trabalho.
      </p>
    <section class="py-0">
        <div class="container">
          <p class="subtitle-bold">Contratos FPS</p>
          <div class="bar mt-1 mb-3"></div>
          <p>
            Nossa parceria vem para descomplicar a sua operação. Com mais de 80
            anos de conhecimento para avaliar e oferecer a melhor solução para
            sua necessidade, gerando ainda mais eficiência e desempenho.
          </p>
          <p class="subtitle3-bold">
            São inúmeros benefícios para a sua operação
          </p>
          <ul class="">
            <li>
              Com uma consultoria especializada, você utilizará o modelo de FPS
              adequado para a
              <strong> máxima produtividade, eficiência e economia</strong>
            </li>
            <li>
              <strong>Redução de até 60% no custo/hora</strong> ao combinar
              produtos de qualidade com a aplicação correta na operação;
            </li>
            <li>
              Diminua o consumo de <strong>combustível em até 5% </strong>com a
              escolha do PFS correto;
            </li>
            <li>
              <strong>Previsibilidade de consumo</strong> e um planejamento
              preciso através de uma consultoria personalizada;
            </li>
            <li>
              <strong>Gerenciar estoque</strong> é complexo e muito custoso. O
              CVA FPS faz isso pra você. Tenha a peça certa no momento certo;
            </li>
            <li>
              Facilitamos o <strong>planejamento financeiro</strong> do seu
              negócio através de faturamento programado com fluxo de caixa
              previsível e organizado;
            </li>
            <li>
              <strong>Condições comerciais exclusivas</strong> para vendas de
              FPS através de contratos.
            </li>
          </ul>
        </div>
      </section>
      <div class="container">
        <p class="subtitle-bold">
          Conheça as características e vantagens de cada sistema de ponta Cat
        </p>
        <div class="bar mt-1 mb-3"></div>
        <div class="row row-cols-1 row-cols-md-3 g-2 mb-3">
          <div class="col vancard">
            <div class="card h-100">
              <div class="card-header bg-warning">
                <h5 class="card-title mb-0 text-center">Série DRT</h5>
                <p class="card-text text-center mb-0 subtext">
                  Para caçambas traseiras de retroescavadeira
                </p>
              </div>
              <div class="card-body vancardbody">
                <ul class="list-unstyled">
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Geometria otimizada, oferece maior penetração e performance;
                  </li>
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>A mais
                    robusta do mercado;
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col vancard">
            <div class="card h-100">
              <div class="card-header bg-warning">
                <h5 class="card-title mb-0 text-center">Série J GD</h5>
              </div>
              <div class="card-body vancardbody">
                <ul class="list-unstyled">
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Custo inicial 70% menor em comparação à outras linhas.
                  </li>
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Facilidade de seleção, perfeita para aplicações de impacto e
                    abrasão médio a moderado.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col vancard">
            <div class="card h-100">
              <div class="card-header bg-warning">
                <h5 class="card-title mb-0 text-center">Série J HD</h5>
              </div>
              <div class="card-body vancardbody">
                <ul class="list-unstyled">
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>Durabilidade
                    30% maior que a J GD;
                  </li>
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Mais perfis de pontas para todas as aplicações, de moderado
                    a alto impacto e abrasão.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 g-4 mb-4">
          <div class="col vancard">
            <div class="card h-100">
              <div class="card-header bg-warning">
                <h5 class="card-title mb-0 text-center">Série K</h5>
              </div>
              <div class="card-body vancardbody">
                <ul class="list-unstyled">
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>Fixação sem
                    martelo com sistema de click;
                  </li>
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Ideal para todos os tipos de aplicação de médio a alto
                    impacto e abrasão;
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col vancard">
            <div class="card h-100">
              <div class="card-header bg-warning">
                <h5 class="card-title mb-0 text-center">Série Advansys</h5>
              </div>
              <div class="card-body vancardbody">
                <ul class="list-unstyled">
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Sistema de trava por giro que permite trocas 75% mais
                    rápidas, resultando em maior disponibilidade da máquina;
                  </li>
                  <li>
                    <i class="bi bi-check-lg text-success me-2"></i>
                    Os adaptadores oferecem uma durabilidade 50% superior em
                    comparação a outras séries.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p class="mt-3 mt-md-0 mb-4 subtitle-bold">
          Escolha o FPS certo em poucos cliques!
        </p>
        <div class="bar mt-1 mb-3"></div>
      </div>
      <h5 class="font-weight-bold">1- Selecione seu equipamento</h5>
    </div>
    <!-- Filter Controls and View Toggle -->
<div id="controls" class="container pt-0 mb-0" style="padding-top: 0px !important; padding-bottom: 0px !important">
      <!-- Filters / Facets -->
      <div id="filters" class="row">
        <div
          id="equipamento-grid-facet"
          class="facet grid-facet col-12 mb-3"
        ></div>
        <h5 class="font-weight-bold">
          2 - Selecione seu modelo do seu equipamento
        </h5>
        <div class="row" style="min-width: 100%">
          <div class="col-12 col-md-11">
            <div id="sales_model2-facet" class="facet col-12 mb-3"></div>
          </div>
          <div class="col-12 col-md-1">
            <!-- Clear Refinements -->
            <div id="clear-refinements" class="custom-clear-refinements"></div>
          </div>
        </div>
        <h5 class="font-weight-bold">
          3 - Selecione o sistema de ponta ou borda
        </h5>
        <div id="tip_system-facet" class="facet grid-facet col-12 mb-3"></div>
        <div id="created-at-facet" class="facet col-12 mb-3"></div>
        <!-- Add more facets as needed -->
      </div>
      <div class="w-100 d-flex justify-content-between">
        <h5 class="font-weight-bold">
          4 - Selecione o sistema de ponta ou borda
        </h5>
        <!-- View Toggle Buttons -->
        <div
          id="view-toggle"
          class="btn-group btn-group-toggle hidden"
          role="group"
          aria-label="View Toggle"
          data-toggle="buttons"
        >
          <button
            id="list-view-btn"
            class="btn btn-secondary"
            aria-pressed="false"
          >
            <i class="bi bi-grid-fill"></i>
          </button>
          <!--  <button id="grid-view-btn" class="btn btn-secondary" aria-pressed="false"><i
                    class="bi bi-grid-3x3-gap-fill"></i></button> -->
          <button
            id="table-view-btn"
            class="btn btn-secondary active"
            aria-pressed="true"
          >
            <i class="bi bi-list-ul"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Hits / Results -->
    <div id="hits" class="container list-view">
      <div class="hit-list"></div>
      <!-- Container for Table View -->
      <div class="hit-table"></div>
    </div>
    <!-- Pagination (Optional) -->
    <div id="pagination" class="container my-3"></div>
    <Footer
      bgcolor="#f5f5f5"
      :buttonless="true"
      :hasform="false"
      gtmterm="pagina_tele_engenharia"
    />
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
  searchBox,
  refinementList,
  hits,
  pagination,
  clearRefinements,
} from "instantsearch.js/es/widgets";
import { highlight, getHighlightedParts } from "instantsearch.js/es/helpers";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  connectSearchBox,
  connectRefinementList,
  connectClearRefinements,
  connectHits,
} from "instantsearch.js/es/connectors";
import Footer from "@/components/Footer.vue";

export default {
  name: "Fps",
  created() {
    window.loadJquery = false;
  },
  unmounted() {
    window.loadJquery = true;
  },
  data() {
    return {
      searchClient: null,
      search: null,
      currentView: "list",
      hits: [],
      items: [],
      refinements: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeSearch();
      this.setupViewToggle();
    });
  },
  components: {
    Footer,
  },
  methods: {
    initializeSearch() {
      // Initialize Algolia client with the correct credentials from Header.vue
      this.searchClient = algoliasearch(
        "E313P20BVQ",
        "8356ec80bbcae8de0b04b91cb8b5b63d"
      );

      // Initialize InstantSearch with the correct index name from Header.vue
      this.search = instantsearch({
        indexName: "fpsteste",
        searchClient: this.searchClient,
      });

      // Add widgets
      this.addSearchWidgets();

      // Start search
      this.search.start();
    },

    addSearchWidgets() {
      // Current View State
      this.currentView = "list"; // default view

      // Function to get current view
      const getCurrentView = () => this.currentView;

      // Custom RefinementList Widget (CardRefinementList)
      const CardRefinementList = (widgetParams) => {
        const {
          container,
          attribute,
          cssClasses = {},
          templates = {},
        } = widgetParams;

        let containerElem;
        let grid;
        let searchRefinementInput;

        const renderFn = (renderOptions, isFirstRender) => {
          const {
            items,
            refine,
            currentRefinement,
            createURL,
            instantSearchInstance,
          } = renderOptions;

          if (isFirstRender) {
            containerElem = document.querySelector(container);

            // Create a container for the cards grid
            grid = document.createElement("div");
            grid.className = "card-grid";
            containerElem.appendChild(grid);

            // Add a search input for refinements
            searchRefinementInput = document.createElement("input");
            searchRefinementInput.type = "text";
            searchRefinementInput.placeholder = "Search within refinements...";
            searchRefinementInput.className = "refinement-search-input";

            // Add event listener for filtering refinements
            searchRefinementInput.addEventListener("input", (e) => {
              const query = e.target.value.toLowerCase();
              const filteredItems = items.filter((item) =>
                item.label.toLowerCase().includes(query)
              );
              renderItems(filteredItems);
            });
          }

          grid = containerElem.querySelector(".card-grid");
          grid.innerHTML = ""; // Clear previous content

          renderItems(items);

          function renderItems(filteredItems) {
            grid.innerHTML = ""; // Clear grid
            filteredItems.forEach((item) => {
              // Create card elements
              const card = document.createElement("div");
              card.className = "facet-card border-0 col-12 col-md-3";

              const checkbox = document.createElement("input");
              checkbox.type = "checkbox";
              checkbox.id = `${attribute}-${item.label}`;
              checkbox.checked = item.isRefined;
              checkbox.addEventListener("change", () => {
                refine(item.value);
              });

              const label = document.createElement("label");
              label.className = "ml-2";
              label.htmlFor = `${attribute}-${item.label}`;
              label.textContent = `${item.label}`;

              card.appendChild(checkbox);
              card.appendChild(label);
              grid.appendChild(card);
            });
          }
        };

        const unmountFn = () => {
          if (containerElem) {
            containerElem.innerHTML = "";
          }
        };

        // Use the connectRefinementList connector
        const connector = connectRefinementList(renderFn, unmountFn);
        return connector({ container, attribute });
      };

      // ==============================
      // Custom Grid Menu Widget
      // ==============================
      const customGridMenu = (widgetParams) => {
        const {
          container,
          attribute,
          limit = 20,
          showMore = false,
        } = widgetParams;

        let containerElem;
        let gridContainer;
        let searchRefinementInput;

        return connectRefinementList((renderOptions, isFirstRender) => {
          const { items, refine, canRefine } = renderOptions;

          if (isFirstRender) {
            containerElem = document.querySelector(container);
            gridContainer = document.createElement("div");
            gridContainer.className = "grid-container";
            containerElem.appendChild(gridContainer);

            searchRefinementInput = document.createElement("input");
            searchRefinementInput.type = "text";
            searchRefinementInput.placeholder = `Search ${attribute}...`;
            searchRefinementInput.className = "refinement-search-input d-none";
            containerElem.insertBefore(searchRefinementInput, gridContainer);

            searchRefinementInput.addEventListener("input", (e) => {
              const query = e.target.value.toLowerCase();
              const filteredItems = items.filter((item) =>
                item.label.toLowerCase().includes(query)
              );
              renderItems(filteredItems, gridContainer, refine);
            });
          }

          gridContainer = containerElem.querySelector(".grid-container");
          gridContainer.innerHTML = "";

          const transformedItems = items.map((item) => {
            switch (item.label) {
              case "Wheel Loader":
                return { ...item, label: "Carregadeiras" };
              case "Excavator Tips":
                return { ...item, label: "Escavadeiras" };
              case "BackHoe Loader":
                return { ...item, label: "Retroescavadeiras" };
              default:
                return item;
            }
          });

          renderItems(transformedItems, gridContainer, refine);

          function renderItems(filteredItems, gridContainer, refineFn) {
            gridContainer.innerHTML = "";
            filteredItems.slice(0, limit).forEach((item) => {
              const card = document.createElement("div");
              card.className = "facet-card";
              if (item.isRefined) {
                card.classList.add("active");
              }

              card.innerHTML = `
              <div class="row">
                <img class="col-5" src="images/${item.label}.png" alt="${item.label}" />
                <span class="col-7">${item.label}</span>
              </div>
            `;

              card.addEventListener("click", () => {
                refineFn(item.value);
              });

              gridContainer.appendChild(card);
            });
          }
        })({ attribute });
      };

      // ==============================
      // Custom Hits Widget
      // ==============================
      const CustomHits = (widgetParams) => {
        const { container, getCurrentView } = widgetParams;

        const renderCustomHits = (renderOptions, isFirstRender) => {
          const { hits } = renderOptions;
          const currentView = getCurrentView();

          container.innerHTML = "";

          if (currentView === "list" || currentView === "grid") {
            const hitList = document.createElement("div");
            hitList.className = "hit-list row";

            hits.forEach((hit) => {
              const hitItem = document.createElement("div");
              hitItem.className = "hit-item col-12 col-md-3 mb-3";
              hitItem.innerHTML = `
              <div class="card mb-3 h-100 alg-card">
                <img class="card-img-top" src="${hit.image}" alt="${
                hit.sales_model2
              }">
                <div class="card-body h-100">
                  <p class="card-text"><strong>Equipamento:</strong> ${highlight(
                    { attribute: "sales_model2", hit }
                  )}</p>
                  <p class="card-text"><strong>Sistema de ponta:</strong> ${
                    hit.tip_system
                  }</p>
                  <p class="card-text"><strong>Part number:</strong> ${
                    hit.part_number
                  }</p>
                  <p class="card-text"><strong>Breve descrição:</strong> ${
                    hit.short_description
                  }</p>
                  <p class="card-text"><strong>Resistência Abrasão:</strong> ${
                    hit.abra
                  }</p>
                  <p class="card-text"><strong>Resistência Impacto:</strong> ${
                    hit.impa
                  }</p>
                  <p class="card-text"><strong>Capacidade Penetração:</strong> ${
                    hit.pene
                  }</p>
                </div>
                <div class="card-footer p-0">
                  <a href="${
                    hit.url
                  }" target="_blank" type="button" class="hitcardbtn btn btn-warning">
                    <i class="bi bi-cart3"></i> Compre agora
                  </a>
                </div>
              </div>
            `;
              hitList.appendChild(hitItem);
            });

            container.appendChild(hitList);
          } else if (currentView === "table") {
            const hitTable = document.createElement("div");
            hitTable.classList = ["hit-table"];

            const table = document.createElement("table");
            table.classList = ["table table-hover"];
            const thead = document.createElement("thead");
            const headerRow = document.createElement("tr");
            headerRow.className = "bg-warning";
            const headers = [
              "Equipamento",
              "Sistema de ponta",
              "Part number",
              "Breve descrição",
              "",
            ];

            headers.forEach((headerText) => {
              const th = document.createElement("th");
              th.textContent = headerText;
              headerRow.appendChild(th);
            });

            thead.appendChild(headerRow);
            table.appendChild(thead);

            const tbody = document.createElement("tbody");

            hits.forEach((hit) => {
              const row = document.createElement("tr");
              row.innerHTML = `
              <td>${highlight({ attribute: "sales_model2", hit })}</td>
              <td>${hit.tip_system}</td>
              <td>${hit.part_number}</td>
              <td>${hit.short_description}</td>
              <td>
                <a href="${
                  hit.url
                }" target="_blank" type="button" class="hittablebtn btn btn-warning">
                  <i class="bi bi-cart3"></i> Compre agora
                </a>
              </td>
            `;
              tbody.appendChild(row);
            });

            table.appendChild(tbody);
            hitTable.appendChild(table);
            container.appendChild(hitTable);
          }
        };

        return connectHits(renderCustomHits)({ container, getCurrentView });
      };

      // Initialize and add widgets
      const clearRefinementsWidget = connectClearRefinements(
        (renderOptions, isFirstRender) => {
          const { refine, hasRefinements } = renderOptions;
          const container = document.querySelector("#clear-refinements");

          if (isFirstRender) {
            const button = document.createElement("button");
            button.innerHTML =
              '<i class="bi bi-arrow-counterclockwise"></i> Limpar tudo';

            button.addEventListener("click", () => {
              refine();
            });

            container.appendChild(button);
          }

          const button = container.querySelector("button");
          button.disabled = !hasRefinements;
        }
      );

      // Add all widgets
      this.search.addWidgets([
        clearRefinementsWidget({
          container: "#clear-refinements",
        }),

        customGridMenu({
          container: "#equipamento-grid-facet",
          attribute: "equipamento",
          limit: 20,
          showMore: false,
        }),

        customGridMenu({
          container: "#tip_system-facet",
          attribute: "tip_system",
          limit: 100,
          showMore: false,
          cssClasses: "col-4",
        }),

        searchBox({
          container: "#searchbox",
          placeholder: "Buscar produtos...",
          showReset: true,
          showSubmit: true,
          showLoadingIndicator: true,
          cssClasses: {
            root: "searchbox",
            input: "form-control",
            submit: "btn btn-primary",
            reset: "btn btn-secondary",
          },
        }),

        CardRefinementList({
          container: "#sales_model2-facet",
          attribute: "sales_model2",
          limit: 100,
          showMore: true,
        }),

        CustomHits({
          container: document.querySelector("#hits"),
          getCurrentView: () => this.currentView,
        }),
      ]);
    },

    setupViewToggle() {
      const listViewBtn = document.getElementById("list-view-btn");
      const tableViewBtn = document.getElementById("table-view-btn");
      const hitsContainer = document.getElementById("hits");

      const saveViewPreference = () => {
        localStorage.setItem("preferredView", this.currentView);
      };

      const switchToListView = () => {
        this.currentView = "list";
        hitsContainer.classList.add("list-view");
        hitsContainer.classList.remove("table-view");
        listViewBtn.classList.add("active");
        tableViewBtn.classList.remove("active");
        saveViewPreference();
        this.search.refresh();
      };

      const switchToTableView = () => {
        this.currentView = "table";
        hitsContainer.classList.add("table-view");
        hitsContainer.classList.remove("list-view");
        tableViewBtn.classList.add("active");
        listViewBtn.classList.remove("active");
        saveViewPreference();
        this.search.refresh();
      };

      // Event Listeners for Toggle Buttons
      listViewBtn.addEventListener("click", switchToListView);
      tableViewBtn.addEventListener("click", switchToTableView);

      // Initialize with stored preference or default view
      const savedView = localStorage.getItem("preferredView");
      if (savedView === "table") {
        switchToTableView();
      } else {
        switchToListView();
      }
    },
  },
};
</script>

<style>
.card-header.bg-warning:first-child {
  padding-top: 1rem !important;
  padding-bottom: 0.75rem !important;
}




.row {
      margin-right: unset;
        margin-left: unset;
}


.card-header.bg-warning {
  height: 70px;
  max-height: 70px;
  border-radius: 5px;
  background: #f2b90c;
  padding: 1.5rem 1.25rem 0.2rem 1.25rem !important;
  min-height: 70px;
}

.container {
  padding: 15px 0px !important;
}

.hidden {
  display: none;
  visibility: hidden;
}

.subtitle-bold {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.8px;
}

.subtitle3-bold {
  color: var(--Base-Colors-Primary, #252525);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.4px;
}

.title-bold {
  color: var(--Base-Colors-Primary, #252525);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.vancardbody {
  border: 1px solid #252525;
  border-radius: 6px;
  margin-top: 16px;
  padding: 0.7rem !important;
}

.vancard {
  max-width: 430px;
  padding-left: 0px;
}

.alg-card {
  max-width: 20em;
  border: 1px solid #d9d9d9 !important;
  padding-bottom: 16px;
}

.btn-black {
  border: 2px solid #fff;
  background-color: #000;
  color: #fff;
}

.btn-black:hover {
  border: 2px solid #000;
  background-color: #fff;
  color: #000;
}

.notalink {
  text-decoration: none;
  color: unset !important;
}

.notalink:hover {
  text-decoration: underline;
  color: unset !important;
}

.sotreqlink-btn {
  color: #ffc107 !important;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ffd126;
  color: #000;
}

.circle:hover {
  color: #fff;
  cursor: pointer;
}

.body-text {
  text-align: left;
  font: normal normal normal 16px Univers LT Std;
  letter-spacing: 0;
  color: #000;
  opacity: 1;
}

a {
  text-decoration: none;
}

img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 95%;
  max-width: 95%;
}

.border-top {
  border-top: 1px solid #000;
  padding-top: 1em !important;
}

button {
  border-radius: 0;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}

.hero-img {
  height: 70vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 500px;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.herohome {
  margin-top: -80px;
  position: relative;
}

.hero-overlay {
  height: 100%;
  background-color: #000;
  position: absolute;
  width: 100%;
  opacity: 0.3;
}

.lh-sm {
  line-height: 62px !important;
}

.link-black {
  color: #000;
}

button,
input {
  margin: 0;
}

button,
input {
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

[type="submit"],
button {
  -webkit-appearance: button;
}

[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[tabindex],
a,
button,
input,
label {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.right {
  float: right;
}

@media (min-width: 0) and (max-width: 47.9375rem) {
  .container {
    padding: 0 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.cool-link {
  display: inline-block;
  color: #ffc22b;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ffc22b;
  transition: width 0.3s;
}

li.show > .cool-link::after {
  width: 100%;
}

.cool-link:hover::after {
  width: 100%;
}

.navbar-sotreq {
  height: 80px;
  z-index: 99;
}

.header-logo {
  width: 166px;
}

.dropdown-toggle.active-dropdown::after {
  transform: rotate(-90deg);
}

.dropdown-menu {
  margin: 0;
}

.dropdown-menu.show {
  display: flex;
  left: 0 !important;
  width: 100%;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-radius: 0 !important;
}

.img-menu-box {
  padding-right: 2em;
  border-right: 1px solid #d6d6d6;
}

a.dropdown-item.dropdown-toggle[aria-expanded="true"] {
  color: #ffc22b !important;
}

#navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li.show > a {
  color: #ffc22b !important;
}

.dropdown-item {
  color: #fff;
  padding: 0.5rem 1rem;
}

.dropdown-item:hover {
  color: #ffc22b !important;
}

.dropdown-item-in {
  color: #000;
  padding: 0.5rem 1rem;
}

.dropdown-item-in:hover {
  color: #ffc22b !important;
}

.dropdown {
  position: revert !important;
}

.navbar-expand-md .navbar-collapse {
  justify-content: center;
}

.navbar.navbar-expand-md.navbar-dark.navbar-sotreq {
  background: #000;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0,
    rgba(0, 0, 0, 0.5202970095593544) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.internal-link {
  color: #000;
}

.navbar.navbar-expand-md.navbar-dark.navbar-sotreq:hover {
  background: #fff !important;
  color: #000;
}

.navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a {
  color: #000;
}

.navbar-sotreq:hover
  > .container
  > #navbarNavDropdown
  > ul
  > li
  > a.nav-link:hover {
  color: #ffc22b !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffc22b !important;
  background-color: unset;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffc22b;
  background-color: unset;
}

#navbarNavDropdown > ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

li.nav-item.dropdown.show > ul.dropdown-menu {
  border: 1px solid #d6d6d6;
  border-radius: 0;
  padding-bottom: 1em;
  padding-top: 1em;
}

li.show > ul.dropdown-menu.show {
  border-top: 1px solid #d6d6d6;
  border-bottom: none;
}

a.internal-link > h6 {
  margin-bottom: 1.5em;
}

h6.internal-link {
  margin-bottom: 1.5em;
}

.upcaret {
  width: 3em;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

a.internal-link.text-muted > h6 {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.dropdown-toggle::after {
  display: block;
  margin-left: unset;
  vertical-align: unset;
  content: "";
  border-top: unset;
  border-right: unset;
  border-bottom: unset;
  border-left: unset;
}

.row.collapse {
  margin-left: -200px;
  left: 0;
  transition: margin-left 0.15s linear;
}

.row.collapse.show {
  margin-left: 0 !important;
}

@media (max-width: 1000px) {
  .hero-logo {
    width: 65%;
  }

  .row.collapse {
    margin-left: 0 !important;
    left: 0 !important;
    overflow: visible;
  }

  .mob-brand {
    height: 56px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .fa-bars {
    align-self: center;
    font-size: 2.333em !important;
    color: #fff !important;
  }

  .header-logo {
    width: 150px;
  }

  .mob-search {
    color: #fff;
    margin-right: 1em;
  }

  .mob-card {
    background-color: #ececec;
    color: #fff;
  }

  .mob-card-header {
    background-color: #ececec;
  }

  .btn-outline-warning {
    color: #ffc107 !important;
  }

  .btn-outline-warning:hover {
    color: #fff !important;
  }

  .mob-internal-link {
    color: #000;
  }

  .btn.btn-link.btn-block.text-left.collapsed {
    color: #000;
    font-size: 24px;
  }

  .btn.btn-link.btn-block.text-left.collapsed:focus {
    box-shadow: none;
    text-decoration: none;
  }

  .btn.btn-link.btn-block.text-left.collapsed:hover {
    box-shadow: none;
    text-decoration: none;
  }

  .btn:focus {
    outline: 0;
    box-shadow: none;
    text-decoration: none;
  }

  .btn.btn-link.btn-block.text-left {
    color: #4a4a4a;
    font-size: 24px;
  }

  .btn.btn-link.btn-block.text-left:hover {
    color: #4a4a4a;
    text-decoration: none;
    font-size: 24px;
  }

  .mob-menu-card-body {
    text-align: left;
  }

  .mob-menu-list {
    list-style: none;
    padding-inline-start: 15px !important;
  }
}

@media (max-width: 538px) {
  .internal-link h6 {
    color: #fff;
  }

  li.nav-item {
    margin-bottom: 1em;
  }

  .upcaret {
    width: 1.5em;
  }

  .container {
    padding: 0px 15px !important;
  }

  .row {
    margin: unset !important;
  }
}

i.fa.fa-angle-right {
  margin-left: 1em;
}

#collapsemObs1 {
  padding-left: 0.7em;
}

.btn.btn-link.btn-block.text-left.cool-link:not(.collapsed)
  > i.fa.fa-angle-right {
  transform: rotate(90deg);
}

#app {
  -webkit-font-smoothing: antialiased;
  text-align: center;
}

.bar {
  margin-top: -5px;
  margin-bottom: 10px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}





#collapseExample {
  height: 80vh;
  min-height: 80vh;
  position: absolute;
  z-index: 10001;
  min-width: 100%;
  background-color: #fafafa;
  overflow: visible;
  left: 0;
  max-height: 81vh;
  margin-top: 4em;
}

.p1rem {
  padding: 0rem;
}

@media (max-width: 755px) {
  .border-md-0 {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
  }

  .modal-body {
    height: 100vh;
  }

  .bg-mobnav {
    background-color: #000;
  }
}

.grid-container {
  flex-direction: column;
}

.card-grid {
  flex-direction: column;
}

.w-100.card.card-body {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
  }

  .facet-card {
    width: 100% !important;
    max-width: 100% !important;
  }

  .vancard {
    margin-bottom: 1em;

  }

  .subtext {
    font-size: 14px;
    white-space: nowrap;
  }
}

@media (min-width: 758px) {
  .border-md-0 {
    border: none !important;
  }

  .grid-container {
    flex-direction: row;
  }

  .card-grid {
    flex-direction: row;
  }
}

.lh-100 {
  line-height: 1;
}

.lh-125 {
  line-height: 1.25;
}

.lh-150 {
  line-height: 1.5;
}

.icon-placeholder {
  width: 48px;
  height: 48px;
  background-color: #ddd;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
}

.card-footer {
  border-top: none;
}

/* Additional styles for custom grid menu */

.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
/*

.facet-card {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  flex: 1 0 30%;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
  width: 33%;
  max-width: 300px;
  padding: 0 10px 0 3px;
  font-size: 16px;
}

.facet-card.active {
  background-color: #f8f9fa;
  border-color: #007bff;
}
*/

    .facet-card {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 10px;
            cursor: pointer;
            flex: 1 0 30%;
            box-sizing: border-box;
            transition: background-color 0.3s, border-color 0.3s;
            width: 33%;
            max-width: 33%;
        }

        .facet-card.active {
            background-color: #f8f9fa;
            border-color: #007bff;
        }
.refinement-search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.hit-table table {
  width: 100%;
  border-collapse: collapse;
}

/* Style for view toggle buttons */

#view-toggle .toggle-button.active {
  background-color: #007bff;
  color: #fff;
}

.card-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

#equipamento-grid-facet > div > div > div > span {
  vertical-align: middle;
  display: flex;
  align-items: center;
  border-left: 2px solid #000;
  padding: 0px 16px;
}

#equipamento-grid-facet.facet.grid-facet.col-12.mb-3
  div.grid-container
  div.facet-card.active {
  background-color: #cbcbcbe6;
}

#equipamento-grid-facet.facet.grid-facet.col-12.mb-3
  div.grid-container
  div.facet-card.active
  div.row
  span.col-7 {
  border-left: 3px solid #ffcd11;
  font-weight: 600;
}

#tip_system-facet > div > div > div > span {
  vertical-align: middle;
  display: flex;
  align-items: center;
}

#tip_system-facet.facet.grid-facet.col-12.mb-3
  div.grid-container
  div.facet-card.active {
  background-color: #cbcbcbe6;
  font-weight: 600;
}

#controls.container.my-3 div#view-toggle.btn-group {
  display: flex;
  justify-content: end;
}

div#view-toggle.btn-group.btn-group-toggle button {
  width: 50px;
  max-width: 50px;
  background-color: #eaeaea;
  color: #a6a6a6;
  border: none;
  margin-left: 3px;
}

div#view-toggle.btn-group.btn-group-toggle button.active {
  width: 50px;
  max-width: 50px;
  background-color: #eaeaea;
  color: #229ed9;
  border: none;
  margin-left: 3px;
  box-shadow: none;
}

.hitcardbtn {
  width: -webkit-fill-available;
  margin: 0px 16px 0px 16px;
  width: fill-available;
}

.btn-warning:hover {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

/* Custom Clear Refinements Button */

.custom-clear-refinements {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.custom-clear-refinements button {
  background-color: #b9b9b9;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s;
}

.custom-clear-refinements button:hover {
  background-color: #e0a800;
}

.custom-clear-refinements button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}


@media (min-width: 1445px) {
  .container {
    max-width: 1320px;
  }
}
</style>
